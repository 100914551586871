import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { Lead } from 'styles/typography'

import PageHeader from 'components/PageHeader'
import Section, { SectionBlock } from 'components/Section'
import MetaTags from 'components/MetaTags'
import Heading from 'components/Heading'
import Wrapper from 'components/Wrapper'
import RichText from 'components/RichText'
import ServiceGrid from 'components/ServiceGrid'
import Related from 'components/industries/Related'
import DevelopmentToolkit from 'components/services/Toolkit/DevelopmentToolkit'
import Process from 'components/services/Process'
import Explore from 'components/services/Explore'

const Development = ({ location }) => (
  <>
    <MetaTags title="React.js & GraphQL software development agency | Volume7" />

    <PageHeader
      breadcrumbs={[
        {
          label: 'Services',
          link: '/services/',
        },
        {
          label: 'Development',
        },
      ]}
      heading="Custom software development"
      description="Our bread and butter. We use the latest technologies to build quality software that's fast, scalable, and secure."
      figure={
        <StaticImage
          src="../assets/images/services/development.jpg"
          layout="fullWidth"
          alt=""
          placeholder="blurred"
        />
      }
    />

    <Section flushTop>
      <Wrapper size="narrow">
        <SectionBlock>
          <RichText>
            <Lead>
              Volume7 was started and is run by technical co-founders. We pride
              ourselves on our engineering-first culture, where code quality and
              best practices are king.
            </Lead>

            <p>
              We've built a diverse team of exceptional software developers and
              have put in place processes to continuously remain at the bleeding
              edge: collaboration, dedicated time for experimenting, mentoring,
              etc. Attracting and retaining top talent is difficult and
              time-consuming, but working with us lets you quickly tap into that
              talent pool which might otherwise be unaccessible.
            </p>
          </RichText>
        </SectionBlock>
        <SectionBlock>
          <Heading size={3} tag="h2">
            Full stack developers
          </Heading>
          <RichText>
            <p>
              We proudly attract, train, and retain software developers who are
              eager to own the full stack, making traditional cross-functional
              engineering teams a thing of the past. Our developers can fully
              immerse themselves into a single client project at a time,
              ensuring complete technical and product comprehension, while
              avoiding unnecessary noise and constant context switching.
            </p>
          </RichText>
        </SectionBlock>
        <SectionBlock>
          <Heading size={3} tag="h2">
            Measure twice, cut once
          </Heading>
          <RichText>
            <p>
              Regular code reviews are an integral part of our software
              development process, providing developers with a hugely beneficial
              extra pair of eyes reviewing their work. Combined with automated
              testing that allows for greater development velocity and prevents
              regressions, we're left with software that's virtually
              bulletproof.
            </p>
          </RichText>
        </SectionBlock>
      </Wrapper>
    </Section>

    <Process />

    <Section>
      <Wrapper size="narrow">
        <SectionBlock>
          <Heading size={3} tag="h2">
            Beyond hard skills
          </Heading>
          <RichText>
            <p>
              Not only are our developers technically versatile, they're also
              strong team players who are used to working alongside other
              developers, designers, strategists, and stakeholders. We pride
              ourselves on having a team that's readily available, and always
              able and willing to clearly articulate their decisions internally
              and to our clients.
            </p>
          </RichText>
        </SectionBlock>
        <SectionBlock>
          <Heading size={3} tag="h2">
            Data meets intuition
          </Heading>
          <RichText>
            <p>
              We combine our analytical approach to decision-making where data
              and analytics informs outcomes with intuition backed by extensive
              product and development experience. We think it's the most
              pragmatic and sensible way to build software, suited for both
              early-stage startups and large entreprises.
            </p>
          </RichText>
        </SectionBlock>
      </Wrapper>
    </Section>

    <Section hasBorder id="services">
      <Wrapper size="narrow">
        <ServiceGrid
          title="Services"
          services={[
            {
              name: 'Web development',
              list: ['Single page apps', 'Marketing websites', 'E-commerce'],
            },
            {
              name: 'Mobile development',
              list: ['React Native', 'iOS', 'Android', 'IoT'],
            },
            {
              name: 'Backend development',
              list: [
                'API',
                'DevOps',
                'Monitoring',
                'Infrastructure',
                'CI/CD pipelines',
                'Scaling strategy',
                'Redundancy',
              ],
            },
            {
              name: 'Security',
              list: [
                'Penetration testing',
                'Vulnerability reporting',
                'Identity/access management',
                'Compliance',
              ],
            },
            {
              name: 'Automated testing',
              list: [
                'Load testing',
                'Performance reports',
                'Unit testing',
                'Regression testing',
                'End-to-end testing',
              ],
            },
            {
              name: 'Digital transformation',
              list: [
                'Legacy upgrades',
                'Middleware abstraction layers',
                'Training',
                'Data ingestion and consolidation',
              ],
            },
            {
              name: 'Cloud migration',
              list: [
                'Migration',
                'Containerization',
                'Microservices',
                'Support SLAs',
              ],
            },
          ]}
        />
      </Wrapper>
    </Section>

    <DevelopmentToolkit />

    <Related projects={['vape', 'usewalter']} />
    <Explore location={location} />
  </>
)

export default Development
