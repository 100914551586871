import React from 'react'
import { ThemeProvider } from 'styled-components'

import { themes } from 'styles/theme'

import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import RichText from 'components/RichText'
import Heading from 'components/Heading'
import Gantt from './Gantt'

const Process = () => (
  <ThemeProvider theme={themes.dark}>
    <Section id="process">
      <SectionBlock>
        <Wrapper size="narrow">
          <Heading size={3} tag="h2">
            A hybrid approach
          </Heading>
          <RichText>
            <p>
              While we aim to focus on results and deliverables, we adopt a
              software development process designed to maximize velocity while
              minimizing risks and unforeseen changes. We advocate for a clear
              product vision paired with short, continuous feature cycles with
              frequent user feedback.
            </p>
          </RichText>
        </Wrapper>
      </SectionBlock>

      <SectionBlock>
        <Wrapper>
          <Gantt />
        </Wrapper>
      </SectionBlock>
    </Section>
  </ThemeProvider>
)

export default Process
