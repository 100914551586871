import React from 'react'
import styled, { css } from 'styled-components'
import { rgba, mix } from 'polished'

import { white, greyDark, greyLight, primaryColor } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { fontWeights, fontSizes } from 'styles/typography'
import { square } from 'styles/helpers'
import { borderRadius } from 'styles/variables'
import { largerThan, smallerThan } from 'styles/media'

import Heading from 'components/Heading'

const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
  margin-left: -${spacing.large};
  margin-right: -${spacing.large};
  padding-left: ${spacing.large};
  padding-right: ${spacing.large};

  ${largerThan.small`
    margin-left: -${spacing.xxLarge};
    margin-right: -${spacing.xxLarge};
    padding-left: ${spacing.xxLarge};
    padding-right: ${spacing.xxLarge};
  `};

  ${largerThan.medium`
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  `};

  ${smallerThan.medium`
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  `};
`

const Inner = styled.div`
  position: relative;
  width: 980px;

  ${largerThan.medium`
    width: 100%;
  `};
`

const Title = styled(Heading)`
  position: absolute;
  top: 10%;
  right: 5%;
  z-index: 2;
  background-color: ${white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${greyDark};
  margin: 0;
  ${square('170px')};

  ${largerThan.small`
    ${square('180px')};
  `};

  ${largerThan.medium`
    ${square('200px')};
  `};

  ${largerThan.large`
    ${square('220px')};
  `};

  ${largerThan.xLarge`
    ${square('240px')};
  `};
`

const Today = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40.4%;
  width: 4px;
  z-index: 2;
  background: linear-gradient(
    180deg,
    ${primaryColor} 0%,
    ${primaryColor} 50%,
    ${rgba(primaryColor, 0)} 100%
  );

  ${largerThan.small`
    left: 39.6%;
  `};

  ${largerThan.medium`
    left: 41.5%;
  `};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    background-color: ${primaryColor};
    border-radius: 50%;
    ${square('14px')};

    ${largerThan.medium`
      transform: translate3d(-50%, -50%, 0);
    `};
  }
`

const Background = styled.div`
  position: absolute;
  top: ${spacing.small};
  left: ${spacing.small};
  right: ${spacing.xLarge};
  bottom: ${spacing.small};
  display: flex;
  justify-content: space-between;

  ${largerThan.small`
    right: 3rem;
  `};

  ${largerThan.medium`
    right: ${spacing.small};
  `};
`

const Line = styled.div`
  width: 1px;
  height: 100%;
  background: linear-gradient(
    180deg,
    ${mix(0.8, greyDark, greyLight)} 0%,
    ${greyDark} 100%
  );
`

const Bars = styled.div`
  position: relative;
  z-index: 1;
  display: grid;
  grid-gap: ${spacing.small};
  margin-right: ${spacing.large};

  ${largerThan.small`
    margin-right: ${spacing.xxLarge};
  `};

  ${largerThan.medium`
    margin-right: 0;
  `};
`

const Bar = styled.div`
  position: relative;
  overflow: hidden;
  color: ${(props) => props.color};
  border-radius: ${borderRadius};
  padding: ${spacing.small} ${spacing.scale(spacing.small, 1.5)};
  font-weight: ${fontWeights.medium};
  background-color: ${(props) => rgba(props.color, 0.2)};
  width: ${(props) => props.width}%;
  margin-left: ${(props) => props.offset}%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${(props) =>
    props.active &&
    css`
      color: ${white};

      &:after {
        background-color: ${(props) => props.color};
      }
    `}

  ${(props) =>
    props.current &&
    css`
      color: ${white};

      &:after {
        background-color: ${(props) => props.color};
        width: 65%;
      }
    `}
`

const Label = styled.span`
  position: relative;
  z-index: 1;
  display: block;

  ${smallerThan.medium`
    font-size: ${fontSizes.small};
  `};
`

const Legend = styled.div`
  display: flex;
  width: 956px;
  margin-top: ${spacing.medium};

  ${largerThan.small`
    width: 940px;
    margin-top: ${spacing.large};
  `};

  ${largerThan.medium`
    width: 100%;
  `};

  ${largerThan.large`
    margin-top: ${spacing.xLarge};
  `};
`

const Section = styled.div`
  border-top: 2px solid ${white};
  color: ${white};
  text-align: center;
  padding-top: ${spacing.small};
  font-weight: ${fontWeights.medium};

  ${largerThan.medium`
    padding-top: ${spacing.scale(spacing.small, 1.5)};
  `};

  ${largerThan.xLarge`
    padding-top: ${spacing.medium};
  `};

  &:first-child {
    opacity: 0.3;
    width: 21%;
  }

  &:last-child {
    flex: 1;
  }

  & + & {
    margin-left: ${spacing.small};

    ${largerThan.medium`
      margin-left: ${spacing.scale(spacing.small, 1.5)};
    `};
  }
`

const steps = [
  {
    name: 'Discovery',
    color: '#EF4444',
    weight: 10,
    offset: 0,
    description: 'Vestibulum id ligula porta felis euismod semper.',
    active: true,
  },
  {
    name: 'Planning',
    color: '#F97316',
    weight: 10,
    offset: 6,
    description: 'Vestibulum id ligula porta felis euismod semper.',
    active: true,
  },
  {
    name: 'Validation',
    color: '#FACC15',
    weight: 10,
    offset: 12,
    description: 'Vestibulum id ligula porta felis euismod semper.',
    active: true,
  },
  {
    name: 'Design',
    color: '#84CC16',
    weight: 18,
    offset: 16,
    description:
      'Wireframing and user interface design with functionality and user experience in mind.',
    active: true,
  },
  {
    name: 'Development',
    color: '#10B981',
    weight: 30,
    offset: 22,
    description: 'Vestibulum id ligula porta felis euismod semper.',
    current: true,
  },
  {
    name: 'QA',
    color: '#06B6D4',
    weight: 14,
    offset: 45,
    description: 'Internal quality assurance testing with code reviews.',
  },
  {
    name: 'UAT',
    color: '#3B82F6',
    weight: 10,
    offset: 55,
    description: 'Vestibulum id ligula porta felis euismod semper.',
  },
  {
    name: 'Review',
    color: '#8B5CF6',
    weight: 10,
    offset: 65,
    description: 'Vestibulum id ligula porta felis euismod semper.',
  },
  {
    name: 'Release',
    color: '#D946EF',
    weight: 10,
    offset: 75,
    description: 'Vestibulum id ligula porta felis euismod semper.',
  },
  {
    name: 'Maintenance',
    color: '#F43F5E',
    weight: 15,
    offset: 85,
    description: 'Vestibulum id ligula porta felis euismod semper.',
  },
]

const Gantt = () => (
  <Container>
    <Inner>
      <Title tag="div" size={4}>
        Our
        <br />
        development
        <br />
        process
      </Title>
      <Today />
      <Bars>
        {steps.map((step, i) => (
          <Bar
            key={i}
            color={step.color}
            active={step.active}
            current={step.current}
            width={step.weight}
            offset={step.offset}
            // data-tip={step.description}
          >
            <Label>{step.name}</Label>
          </Bar>
        ))}
      </Bars>

      <Background>
        {[...Array(22)].map((i) => (
          <Line key={i} />
        ))}
      </Background>
    </Inner>
    <Legend>
      <Section>Clear vision</Section>
      <Section>Continuous feature cycle</Section>
    </Legend>
  </Container>
)

export default Gantt
