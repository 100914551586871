import React from 'react'

import Toolkit from 'components/services/Toolkit'

import AWS from 'assets/images/services/toolkit/aws.svg'
import GraphQL from 'assets/images/services/toolkit/graphql.svg'
import Gatsby from 'assets/images/services/toolkit/gatsby.svg'
import Kubernetes from 'assets/images/services/toolkit/kubernetes.svg'
import Prisma from 'assets/images/services/toolkit/prisma.svg'
import Docker from 'assets/images/services/toolkit/docker.svg'
import Snowflake from 'assets/images/services/toolkit/snowflake.svg'
import ReactLogo from 'assets/images/services/toolkit/react-logo.svg'
import Node from 'assets/images/services/toolkit/node.svg'
import Netlify from 'assets/images/services/toolkit/netlify.svg'
import Google from 'assets/images/services/toolkit/google-cloud.svg'
import TypeScript from 'assets/images/services/toolkit/typescript.svg'
import Next from 'assets/images/services/toolkit/next.svg'
import Shopify from 'assets/images/services/toolkit/shopify.svg'
import Metabase from 'assets/images/services/toolkit/metabase.svg'
import Serverless from 'assets/images/services/toolkit/serverless.svg'
import Elastic from 'assets/images/services/toolkit/elasticsearch.svg'
import Vercel from 'assets/images/services/toolkit/vercel.svg'
import Forest from 'assets/images/services/toolkit/forest.svg'
import Twilio from 'assets/images/services/toolkit/twilio.svg'
import Azure from 'assets/images/services/toolkit/azure.svg'

const DevelopmentToolkit = () => (
  <Toolkit
    heading="Development toolkit"
    description="We pride ourselves on being at the forefront of new technologies, but we're comfortable working with legacy systems."
    items={[
      {
        figure: <ReactLogo />,
        label: 'React',
      },
      {
        figure: <Node />,
        label: 'Node.js',
      },
      {
        figure: <GraphQL />,
        label: 'GraphQL',
      },
      {
        figure: <Prisma />,
        label: 'Prisma',
      },
      {
        figure: <Docker />,
        label: 'Docker',
      },
      {
        figure: <Kubernetes />,
        label: 'Kubernetes',
      },
      {
        figure: <Snowflake />,
        label: 'Snowflake',
      },
      {
        figure: <TypeScript />,
        label: 'TypeScript',
      },
      {
        figure: <Next />,
        label: 'Next.js',
      },
      {
        figure: <Gatsby />,
        label: 'Gatsby',
      },
      {
        figure: <Elastic />,
        label: 'Elasticsearch',
      },
      {
        figure: <Google />,
        label: 'Google Cloud',
      },
      {
        figure: <AWS />,
        label: 'AWS',
      },
      {
        figure: <Azure />,
        label: 'Azure',
      },
      {
        figure: <Serverless />,
        label: 'Serverless',
      },
      {
        figure: <Netlify />,
        label: 'Netlify',
      },
      {
        figure: <Vercel />,
        label: 'Vercel',
      },
      {
        figure: <Forest />,
        label: 'Forest Admin',
      },
      {
        figure: <Metabase />,
        label: 'Metabase',
      },
      {
        figure: <Shopify />,
        label: 'Shopify',
      },
      {
        figure: <Twilio />,
        label: 'Twilio',
      },
    ]}
  />
)

export default DevelopmentToolkit
